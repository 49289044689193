<template>
  <div class="DeviceIcon">
    <el-tooltip
      :content="typeFormatted"
      placement="top"
    >
      <component
        :is="`icon-${props.type}`"
        class="tw-w-full"
      />
    </el-tooltip>
  </div>
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'web',
  },
});

// Computed
const typeFormatted = computed(() => {
  if (props.type === 'ios') {
    return 'iOS';
  }

  return _capitalize(props.type);
});
</script>

<style setup>
.DeviceIcon {
  @apply tw-text-muted tw-w-4 tw-h-auto tw-flex tw-items-center;
}
</style>
