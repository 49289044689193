<template>
  <div v-if="props.request?.deleted">
    <el-tooltip
      v-if="props.request.deleted"
      :content="`Smazaný ${dayjs(props.request.deleted).format('l, LT')}`"
      placement="top"
    >
      <icon-trash class="tw-text-muted tw-w-3.5 tw-h-auto tw-flex tw-items-center" />
    </el-tooltip>
  </div>
</template>

<script setup>
const props = defineProps({
  request: {
    type: Object,
    default: null,
  },
});
</script>
